import React from 'react'
import styled from 'styled-components'
import { Section } from "../Section"
import WhitePhone from "../../images/white-phone.png"

const CTA = styled.div`
	display: flex;
    flex-wrap: wrap;
    margin:0 -15px;
	align-items:center;
`
const CtaLeft = styled.div`
	position: relative;
    width: 100%;
	padding:0 15px;
	text-align:center;
	@media (min-width: 992px){
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
		text-align:left;
	}
`
const CtaRight = styled.div`
	position: relative;
    width: 100%;
	padding:0 15px;
	text-align:center;
	@media (min-width: 992px){
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
		text-align:right;
	}
`
const TextPart = styled.div`	
	& h2 {
		color: #fff;
		font-size: 24px;
  		line-height: 32px;
		@media (min-width: 768px) {  
			font-size: 34px;
			line-height: 44px;  
		}
		@media (min-width: 992px) {  
			font-size: 54px;
			line-height: 64px;
		}
	}
	& p {
		color: #fff;
		@media (min-width: 992px) {  
			font-size: 18px;
			line-height: 36px;
		}
	}
`
const ButtonWrap = styled.div`
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	border: 1px solid white;
	border-radius: 10px;
	padding: 15px 40px;
	@media (min-width: 992px) {  
		padding: 30px 60px;
		max-width:350px;
		width:100%;
	}
	& a {
		font-family: Chakra Petch;
		font-weight: 700;
		color: white;
		text-decoration: none;
		font-size: 18px;
  		line-height: 28px;
		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 28px;
		}
		@media (min-width: 992px) {  
			font-size: 24px;
    		line-height: 32px;
		}
		@media (min-width: 1200px) {  
			font-size: 28px;
    		line-height: 34px;
		}
		& img {
		 	vertical-align: middle;
			 @media (min-width: 1200px) {  
				width:24px;
				height:24px;
			}
		}
	}
	&:hover {
		background: #062c44;
		border-color: #fff;
		cursor: pointer;
	}
`
const BackgroundImage = styled.div`
	background: url(//images.ctfassets.net/z75g1qwq4el7/1aEQQP4amjqrsmVdUPmD3K/879ffd1d8b4272dbd20be49376500d86/cta-background.png);
	background-size: cover;
	height: 100%;
	width: calc(100% - 10px);
	position: absolute;
	top: 0;
	opacity: 0.1;
`

class CallToAction extends React.Component {
	render() {
		const {data,location} = this.props
		if(data!==undefined){
			return(
			<Section bg="#ED602B" pt="158px" pb="143px" >
				<BackgroundImage />
				<div className="container">
					<CTA>
						<CtaLeft>
							<TextPart>
								<h2>{data.title}</h2>
								<p>{data.description.description}</p>
							</TextPart>
						</CtaLeft>
						<CtaRight>
							<ButtonWrap>
								<a href="tel:8777541818">
									<img src={WhitePhone} alt="call-icon" /> (877) 754-1818
								</a>
							</ButtonWrap>
						</CtaRight>
					</CTA>
				</div>
			</Section>
		)
		} else {
			return(
				<Section bg="#ED602B" pt="158px" pb="143px" >
					<BackgroundImage />
					<div className="container">
						<CTA>
							<CtaLeft>
								{(location != undefined && location.path === "/metal-sheds/") ?
									<TextPart>
										<h2>Looking for a Metal Shed that fits your needs?</h2>
										<p>Talk to Building Specialists at Probuilt Steel and start designing today!</p>
									</TextPart>
								:
									<TextPart>
										<h2>Didn’t find what you were looking for?</h2>
										<p>We help our customers to find the best carport to perfectly fit their needs and budget.</p>
									</TextPart>
								}
							</CtaLeft>
							<CtaRight>
								<ButtonWrap>
									<a href="tel:8777541818"><img src={WhitePhone} alt="call-icon" /> (877) 754-1818</a>
								</ButtonWrap>
							</CtaRight>
						</CTA>
					</div>
				</Section>
			)
		}
	}
}

export default CallToAction