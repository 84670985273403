import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Section, SectionTitle } from "../components/Section"
import ProductCardList from "../components/ProductCardList"
import CallToAction from "../components/CallToAction"
import GetInTouch from "../components/GetInTouch"
import ProductRoofHero from "../sections/Product/ProductRoofHero"

const SectionForm = styled(Section)`
	border-top: 1px solid #98dfff;
	box-shadow: rgba(152, 223, 225, 0.2) 0px 15px 45px;
	border-bottom: 1px solid #98dfff;	 
`
class ProductRoofCategoryTemplate extends React.Component {
	render() {
		const pageData = this.props.data.contentfulProductRoofCategoryLanding;
		const  productData = this.props.data.allContentfulProduct;
		let currentData = [];
		let kitProducts = [];
		let garageProducts = "";
		productData.edges.forEach(edge=>{
			if((edge.node.productCategory.name === pageData.productCategory.name) && (edge.node.productRoofType.name === pageData.productRoofType.name)){
				currentData.push(edge)
			} else if (((edge.node.productCategory.name === pageData.productCategory.name) && (pageData.productRoofType.name.indexOf("kits") >= -1)) ){
				kitProducts.push(edge)
			} else if ((pageData.productCategory.name === "Steel Buildings")) {
				garageProducts = productData.edges.filter( data => data.node.productCategory.name === "Garages");		
			}
		})
		if (garageProducts !== " " && garageProducts.length > 0){
			kitProducts = garageProducts;
		}
		
		return(
			<Layout location={this.props.location}>
				<SEO title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
				{ ((currentData.length !== 0) && (kitProducts.length !== 0) ) &&
					<>
						<ProductRoofHero data={pageData} product={currentData}  />
						<SectionForm pt="60px" pb="60px" bg="#fff">
							<div className="container">
								<SectionTitle mb="30px">Get In Touch</SectionTitle>
								<GetInTouch location={this.props.location} sourceFormName={`${pageData.productRoofType.name} Form`} />
							</div>
						</SectionForm>
						<Section pt="95px" pb="90px" bg="#F4FBFF">
						<SectionTitle>
							{pageData.productListTitle}
						</SectionTitle>
						<div className="container">
							<ProductCardList data={currentData} location={this.props.location} />
						</div>
						</Section>
					</>
				}
	    		{ ((currentData.length === 0) && (kitProducts.length !== 0)) &&
					<>
						<ProductRoofHero data={pageData} product={kitProducts}  />
						<Section pt="95px" pb="90px" bg="#F4FBFF">
						<SectionTitle>
							{pageData.productListTitle}
						</SectionTitle>
						<div className="container">
							<ProductCardList data={kitProducts} location={this.props.location} />
						</div>
						</Section>
					</>
				}
				{ ((currentData.length === 0) && (kitProducts.length === 0) ) &&
					<ProductRoofHero data={pageData} product={kitProducts}  />
				}
	    	<CallToAction />
			</Layout>
		)
	}
}

export default ProductRoofCategoryTemplate

export const pageQuery = graphql`
  query roofCategoryLandigQuery($id: String!) {
    contentfulProductRoofCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
	      metaDescription
	    }
      heroTitle
      heroDescription {
        childMarkdownRemark {
					html
				}
      }
      productListTitle
	    productCategory {
	      name
	    }
	    productRoofType {
	      name
	    }
      content {
        content
      }
    }
    allContentfulProduct(sort: {fields: skuNumber}) {
	    edges {
	      node {
	      	id
					skuNumber
					imageSrc
	        image {
		        fluid(maxWidth: 700, quality: 100) {
		          aspectRatio
		          base64
		          sizes
		          src
		          srcSet
		          srcSetWebp
		          srcWebp
		        }
		      }
	        title
	        price
	        priceDescription
	        productServices {
	          spaceId
	          service
	          image {
	            fluid(maxWidth: 45, quality: 100) {
	              aspectRatio
	              base64
	              sizes
	              src
	              srcWebp
	              srcSetWebp
	              srcSet
	            }
	          }
	        }
	        productRoofType {
	          name
	        }
	        productCategory {
	          name
	        }
	      }
	    }
	  }
  }
`

