import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { BreadCrumb } from "../../components/Section"
import ProductImageCarousel from "../../components/ProductImageCarousel"

const Wrapper = styled.div`
  padding-top: 207px;
  padding-bottom: 44px;
  & h1 {
    margin-top: 40px;
    color: #062c44;
  }
  @media (max-width: 768px) {
    padding-top: 150px;
    & h1 {
      margin-top: 20px;
    }
  }
`
const HeroContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-column-gap: 30px;
  grid-template-row: auto;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 50px;
    margin: auto;
  }
`
const HeroTitleAndDesc = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  h1 {
    margin: 40px auto 10px auto;
  }
`

class ProductRoofHero extends React.Component {
  render() {
    const { data, product } = this.props
    const categoryUrl = data.productCategory.name
      .split(" ")
      .join("-")
      .toLowerCase()

    return (
      <Wrapper>
        {product.length !== 0 && (
          <div className="container">
            <BreadCrumb top="3px" mTop="-20px">
              <Link to="/">Home</Link> /{" "}
              {data.productCategory.name !== "Steel Buildings" ? (
                <>
                  {" "}
                  <Link to={`/${categoryUrl}`}>
                    {data.productCategory.name}
                  </Link>{" "}
                  / {""}{" "}
                </>
              ) : (
                ""
              )}
              <span>
                {data.productRoofType.name}{" "}
                {data.productRoofType.name === "Metal Barn Kits" ||
                data.productRoofType.name === "Metal Carport Kits" ||
                data.productRoofType.name === "Metal Garage Kits" ||
                data.productRoofType.name === "Metal Buildings Kits"
                  ? null
                  : data.productCategory.name}
              </span>
            </BreadCrumb>
            <HeroContent>
              <div>
                <h1>{data.heroTitle}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </div>
              <div>
                <ProductImageCarousel product={product} />
              </div>
            </HeroContent>
          </div>
        )}
        {product.length === 0 && (
          <div className="container">
            <BreadCrumb top="3px" mTop="-20px">
              <Link to="/">Home</Link> / {data.productCategory.name} /{" "}
              <span>{data.productRoofType.name} </span>
            </BreadCrumb>
            <HeroTitleAndDesc>
              <h1>{data.heroTitle}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.heroDescription.childMarkdownRemark.html,
                }}
              />
            </HeroTitleAndDesc>
          </div>
        )}
      </Wrapper>
    )
  }
}

export default ProductRoofHero
