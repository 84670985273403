import React, { useState } from 'react'
import styled from 'styled-components'
import SectionButton from '../Button/SectionButton'
import ProductCard from "../ProductCard"

const ProductGrid = styled.div`
	margin-top: 50px;
	margin-bottom: 50px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: auto;
	grid-column-gap: 50px;
	grid-row-gap: 50px;
	@media(max-width: 767px) {
		grid-template-columns: repeat(1, 1fr);
	}
`
const LoadMoreButton = styled.button`
	display: block;
	position: relative;
	background: #010920;
	overflow: hidden;
	width: 350px;
	height: 64px;
	border: 2px solid #010920;
	border-radius: 10px;
	text-align: center;
	margin: auto;
	transition: 0.5s ease-in-out;
	& div {
		z-index: 10;
		padding: 15px;
		color: white;
		font-size: 24px;
		line-height: 29px;
		font-family: Chakra Petch;
		font-weight: 700;
		position: relative;
		transition: 0.5s ease-in-out;
	}
	& :focus {
		outline: none;
	}
	&:before,
	&:after {
		content: '';
		position: absolute;
		transition: 0.5s ease-in-out;
		z-index: 1;
		top: 0;
	}

	&:before {
		left: 0px;
		border-right: 50px solid transparent;
		border-top: 64px solid #1E4156;
		width: calc(50% + 26px);
		transform: translateX(0%);
		transition: 0.5s ease-in-out;
	}

	&:after {
		right: 0px;
		border-left: 50px solid transparent;
		border-bottom: 64px solid #062C44;
		width: calc(50% + 26px);
		transform: translateX(0%);
		transition: 0.5s ease-in-out;
	}

	&:hover {
		border: 2px solid #ED602B;
		cursor: pointer;
		background: #ED602B;	
		& div {
			color: white;	
		}
		&:before { 
			transform: translateX(-100%);
			transition: 0.5s ease-in-out;
		}
		&:after { 
			transform: translateX(100%);
			transition: 0.5s ease-in-out;
		}
	}
	@media(max-width: 1024px) {
		&:hover,
		&:focus,
		&:active {
			border: 2px solid #010920;
			background: #010920;
			& div {
				color: white;
			}
			&:before { 
				transform: none;
			}
			&:after { 
				transform: none;
			}
		}
	}
`
const ProductCardList = ({ data, layout = "", location }) => {
	const [limit, setLimit] = useState(6);
	return(
		<div>
			<ProductGrid>
				{
					data.map((prodcut, i)=>{
						if(i < limit) {
							return(
								<ProductCard data={prodcut} order={i} key={i} location={location} />
							)
						}
					})
				}
			</ProductGrid>
			{ layout === "" && limit < data.length && (
				<LoadMoreButton onClick={()=> setLimit(limit + 6)}><div>Load More</div></LoadMoreButton>
			)}
		</div>
	)
}

export default ProductCardList